import { FormNode } from "../../Common/FormFlow";
import { CFIApplicationKeys } from "../applicationFormFlow";
import { ServiceFormSpec } from "../applicationFormSpec";
import { CFIFormResult } from "../cfiFormResult";
import { ServiceSegment } from "./ServiceSegment";
import { RadioFormField, TextFormField } from "../../Common/Fields";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { appendWithoutHtml } from "../../Common/appendInuktitut";

export type ServiceKey =
  | "firstTimePrompt"
  | "stepsTaken"
  | "existingRequestFileNumber"
  | "services";

export function buildServiceFlow(
  t: ServiceFormSpec,
  iu: ServiceFormSpec,
): FormNode<CFIFormResult, CFIApplicationKeys>[] {
  return [
    {
      id: "firstTimePrompt",
      element: (
        <RadioFormField
          name="firstTimeRequest"
          label={(t) => t.service.firstTimeRequest.question}
          options={[
            {
              value: "Yes",
              text: (t) => t.service.firstTimeRequest.yes,
            },
            {
              value: "No",
              text: (t) => t.service.firstTimeRequest.no,
            },
          ]}
          required
          showSpace
        />
      ),
      transitions: [
        {
          to: "stepsTaken",
          on: (r) =>
            r.applicationType === "group" && r.firstTimeRequest === "No",
        },
        {
          to: "existingRequestFileNumber",
          on: (r) =>
            (r.applicationType === "oneTimeProject" ||
              r.applicationType === "structuringProject") &&
            r.firstTimeRequest === "No",
        },
        { to: "services", on: (r) => r.firstTimeRequest === "Yes" },
      ],
    },

    {
      id: "existingRequestFileNumber",
      element: (
        <>
          <ConvertToMui
            getField={(t) => t.service.existingRequestFileNumber.question}
          />
          <TextFormField
            name="existingRequestFileNumber"
            required
            placeholder={appendWithoutHtml(
              t,
              iu,
              (t) => t.existingRequestFileNumber.placeholder,
              " / ",
            )}
          />
        </>
      ),
      transitions: [
        {
          to: "stepsTaken",
          on: (r) =>
            (r.applicationType === "oneTimeProject" ||
              r.applicationType === "structuringProject") &&
            r.existingRequestFileNumber != null,
        },
      ],
    },

    {
      id: "stepsTaken",
      element: (
        <>
          <ConvertToMui getField={(t) => t.service.stepsTaken.question} />
          <TextFormField
            name="stepsTakenSinceLastRequest"
            multiline
            required
            placeholder={appendWithoutHtml(
              t,
              iu,
              (t) => t.stepsTaken.placeholder,
              " / ",
            )}
          />
        </>
      ),
      transitions: [
        {
          to: "services",
          on: (r) =>
            (r.applicationType === "group" ||
              r.applicationType === "structuringProject" ||
              r.applicationType === "oneTimeProject") &&
            r.stepsTakenSinceLastRequest != null,
        },
      ],
    },

    {
      id: "services",
      element: <ServiceSegment />,
      transitions: [
        {
          to: "documents",
          on: (r) => r.finishedAddingServices,
        },
      ],
    },
  ];
}
