import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SelectFormField, TextFormField } from "../../Common/Fields";
import { useField } from "react-final-form";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { useTranslatedField, useTranslations } from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { useBreakpoints } from "../../Common/useBreakpoints";
import { useFieldValue } from "../../Common/useFieldValue";
import { ApplicationType } from "../Elimination/SelectApplicationType";

const personalOptions = [
  "parentOrGuardian",
  "fosterParent",
  "self",
  "familyMember",
] as const;

const organizationOptions = [
  "educational",
  "healthcare",
  "socialServices",
  "communityWorker",
  "directionOfYouthProtection",
] as const;
export type RelationshipOptions =
  | (typeof personalOptions)[number]
  | (typeof organizationOptions)[number];

export interface RelationSelectorProps {}

export function RelationSelector({}: RelationSelectorProps) {
  const {
    input: { value },
  } = useField<RelationshipOptions | "other">("relationship");

  const relationshipLabel = useTranslatedField(
    (t) => t.applicant.relationship.label,
    " / ",
  );
  const otherOption = useTranslatedField((t) => t.common.other, " / ");
  const pleaseSpecifyLabel = useTranslatedField(
    (t) => t.common.pleaseSpecify,
    " / ",
  );

  const { t, iu } = useTranslations("applicant");

  const { sm } = useBreakpoints();

  const type = useFieldValue<ApplicationType>("applicationType");

  return (
    <>
      <ConvertToMui getField={(t) => t.applicant.relationship.prompt} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={value === "other" ? 6 : 12}>
          <SelectFormField
            name="relationship"
            label={relationshipLabel}
            options={[
              ...(type === "personal"
                ? personalOptions.map((r) => ({
                    value: r,
                    text: appendWithoutHtml(
                      t,
                      iu,
                      (t) => t.relationship.options[r],
                      " / ",
                    ),
                  }))
                : []),
              ...organizationOptions.map((r) => ({
                value: r,
                text: appendWithoutHtml(
                  t,
                  iu,
                  (t) => t.relationship.options[r],
                  " / ",
                ),
              })),
              {
                value: "other",
                text: otherOption,
              },
            ]}
            showSpace
          />
        </Grid>
        {value === "other" && (
          <Grid item xs={12} sm={6}>
            <TextFormField
              name="customRelationship"
              label={pleaseSpecifyLabel}
              showSpace={!sm}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
