import { useGetServiceLabel } from "../../ServiceOptionsProvider";
import { useTranslatedField, useTranslations } from "../../TranslationProvider";
import { ExtendedTableColumn } from "@torqit/crew-2ls";
import {
  CFIService,
  PaymentClientType,
  PaymentFormChild,
  PaymentFormService,
} from "../../cfiFormResult";
import { appendWithoutHtml } from "../../../Common/appendInuktitut";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Typography } from "@mui/material";
import React from "react";
import { calculateTotal } from "../../Conclusion/RequestSummary/useRequestSummaryColumns";

const currencyFormat = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
});

export function usePaymentRequestSummaryColumns(
  children?: PaymentFormChild[],
  clientType?: PaymentClientType,
) {
  const { t, iu } = useTranslations("conclusion");

  const getServiceLabel = useGetServiceLabel();

  const columns: ExtendedTableColumn<Partial<PaymentFormService>>[] = [
    {
      key: "service",
      title: appendWithoutHtml(t, iu, (t) => t.requestSummary.service, " / "),
      render: (s) => {
        if (!s.service) {
          return;
        }

        if (s.service.startsWith("Other")) {
          return s.otherService;
        } else {
          return getServiceLabel(s.service);
        }
      },
      footer: () => (
        // By placing our labels into an absolute box within a relative box,
        // they no longer need to fit within the cell, so they won't blow up
        // the column size. Nothing else needs to display on the footer,
        // so it'll never overlap any other content. A little hacky, but
        // it gets the job done
        <Box position="relative" height={50} width="100%">
          <Box position="absolute" textAlign="right" width={500} right={0}>
            <Typography fontWeight="bold" fontSize={14}>
              {t.requestSummary.grandTotal}:
            </Typography>
            <Typography fontWeight="bold" fontSize={14}>
              {iu.requestSummary.grandTotal}:
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      key: "cost",
      title: appendWithoutHtml(t, iu, (t) => t.requestSummary.cost, " / "),
      render: (s) => currencyFormat.format(Number(s.cost)),
      footer: (data) => (
        <Box pr={3}>
          <Typography fontWeight="bold">
            {currencyFormat.format(
              data?.reduce(
                (sum, current) => (sum += Number(current.cost) ?? 0),
                0,
              ) ?? 0,
            )}
          </Typography>
        </Box>
      ),
    },
  ];

  if (clientType === "personal") {
    const childColumns: ExtendedTableColumn<PaymentFormService>[] = [
      {
        key: "children",
        title: appendWithoutHtml(
          t,
          iu,
          (t) => t.requestSummary.children,
          " / ",
        ),
        render: (s) => {
          const c = children?.find((c) => c.id === s.child);

          return (
            <Box>
              {c?.firstName} {c?.lastName} (
              {c?.dateOfBirth ? dayjs(c.dateOfBirth).format("ll") : "Unknown"})
            </Box>
          );
        },
      },
    ];

    return childColumns.concat(columns);
  }

  return columns;
}
