import React from "react";
import { RadioFormField, TextFormField } from "../../Common/Fields";
import { Stack } from "@mui/material";
import { useTranslatedField } from "../TranslationProvider";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import TagIcon from "@mui/icons-material/Tag";

export interface PaymentTypePromptProps {}

export function PaymentTypePrompt({}: PaymentTypePromptProps) {
  const approvedFileNumberLabel = useTranslatedField(
    (t) => t.payment.approvedFileNumber.label,
    " / ",
  );

  return (
    <Stack gap={2}>
      <RadioFormField
        label={(t) => t.payment?.clientType?.prompt}
        name="clientType"
        vertical
        options={[
          {
            value: "personal",
            text: (t) => t.payment.clientType.personal,
          },
          {
            value: "group",
            text: (t) => t.payment.clientType.group,
          },
          {
            value: "oneTimeProject",
            text: (t) => t.payment.clientType.oneTimeProject,
          },
          //Removing structuring project prompt for now
          // {
          //   value: "structuringProject",
          //   text: (t) => t.payment.clientType.structuringProject,
          // },
        ]}
        showSpace
      />
      <ConvertToMui getField={(t) => t.payment.approvedFileNumber.prompt} />
      <TextFormField
        name="approvedFileNumber"
        label={approvedFileNumberLabel}
        required
        icon={<TagIcon />}
      />
    </Stack>
  );
}
