import { FormNode } from "../../Common/FormFlow";
import { CFIApplicationKeys } from "../applicationFormFlow";
import { ChildrenFormSpec } from "../applicationFormSpec";
import { CFIFormResult } from "../cfiFormResult";
import { ChildrenEditor } from "./ChildrenEditor";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { OverTenChildrenPrompt } from "./OverTenChildrenPrompt";

export type ChildrenKey = "groupChildrenCount" | "children";

export function buildChildrenFlow(
  t: ChildrenFormSpec,
  iu: ChildrenFormSpec,
): FormNode<CFIFormResult, CFIApplicationKeys>[] {
  return [
    {
      id: "groupChildrenCount",
      element: <OverTenChildrenPrompt />,
      transitions: [
        {
          to: "children",
          on: (r) => r.applicationType === "group" && r.overTenChildren != null,
        },
      ],
    },

    {
      id: "children",
      title: appendWithoutHtml(t, iu, (t) => t.cardTitle, " / "),
      element: <ChildrenEditor />,
      transitions: [
        {
          to: "services",
          on: (r) =>
            r.applicationType === "personal" && r.finishedAddingChildren,
        },
        {
          to: "firstTimePrompt",
          on: (r) => r.finishedAddingChildren,
        },
      ],
    },
  ];
}
