import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import {
  DateFormField,
  SelectFormField,
  TextFormField,
} from "../../Common/Fields";
import { FormSpy } from "react-final-form";
import { CFIFormResult } from "../cfiFormResult";
import PeopleIcon from "@mui/icons-material/People";
import {
  useTranslatedField,
  useTranslatedSelectOptions,
  useTranslations,
} from "../TranslationProvider";
import {
  appendWithoutHtml,
  mergeIntoSelectOptions,
} from "../../Common/appendInuktitut";
import { ServiceSelector } from "./ServiceSelector";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useFieldValue } from "../../Common/useFieldValue";
import { ApplicationType } from "../Elimination/SelectApplicationType";

export interface ServiceFieldsProps {
  name: string;
  index: number;
}

export function ServiceFields({ name, index }: ServiceFieldsProps) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const { t, iu } = useTranslations("service");

  const frequency = useTranslatedField(
    (t) => t.service.payment.frequency,
    " / ",
  );
  const cost = useTranslatedField((t) => t.service.payment.cost, " / ");
  const serviceType = useTranslatedField((t) => t.service.serviceType, " / ");

  const startDate = useTranslatedField((t) => t.service.startDate, " / ");
  const endDate = useTranslatedField((t) => t.service.endDate, " / ");

  const payorOptions = useTranslatedSelectOptions(
    (t) => t.service.whoIsPaying.options,
  );
  const otherLabel = useTranslatedField((t) => t.common.other, " / ");
  const pleaseSpecify = useTranslatedField(
    (t) => t.common.pleaseSpecify,
    " / ",
  );

  const payor = useFieldValue<string>(`${name}.payor`);

  const applicationType = useFieldValue<ApplicationType>("applicationType");

  return (
    <Grid container spacing={1}>
      <FormSpy<CFIFormResult>
        subscription={{ values: true }}
        render={({ values }) =>
          values.applicationType === "personal" ? (
            <Grid item xs={12} sm={6} md={4}>
              <SelectFormField
                name={`${name}.child`}
                label={appendWithoutHtml(
                  t,
                  iu,
                  (t) => t.serviceLabels.children,
                  " / ",
                )}
                options={values.children.map((c) => ({
                  value: c.id,
                  text: `${c.firstName} ${c.lastName}`,
                }))}
                icon={<PeopleIcon />}
                required
              />
            </Grid>
          ) : undefined
        }
      />
      <ServiceSelector name={name} index={index} />
      <Grid item xs={12} md={8}>
        <TextFormField
          name={`${name}.serviceType`}
          label={serviceType}
          required
          showSpace
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFormField
          name={`${name}.cost`}
          label={cost}
          allowedContent="numeric"
          icon={<AttachMoneyIcon />}
          required
        />
      </Grid>
      {(applicationType === "personal" || applicationType === "group") && (
        <>
          <Grid item xs={12} sm={payor === "Other" ? 6 : 12}>
            <SelectFormField
              name={`${name}.payor`}
              label={appendWithoutHtml(t, iu, (t) => t.whoIsPaying.prompt, " ")}
              options={[
                ...payorOptions,
                {
                  value: "Other",
                  text: otherLabel,
                },
              ]}
              showSpace
              required
            />
          </Grid>
          {payor === "Other" && (
            <Grid item xs={12} sm={6}>
              <TextFormField
                name={`${name}.customPayor`}
                label={pleaseSpecify}
                showSpace={!sm}
                required
              />
            </Grid>
          )}
        </>
      )}

      {applicationType !== "oneTimeProject" &&
        applicationType !== "structuringProject" && (
          <>
            <Grid item xs={12} sm={6}>
              <DateFormField
                name={`${name}.startDate`}
                label={startDate}
                required
                showSpace
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateFormField name={`${name}.endDate`} label={endDate} />
            </Grid>
          </>
        )}
    </Grid>
  );
}
