import React from "react";
import { ApplicationFormSpec } from "../applicationFormSpec";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { SelectFormField, TextFormField } from "../../Common/Fields";
import { FormSpy } from "react-final-form";
import { CFIFormResult } from "../cfiFormResult";
import { Grid } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import {
  useTranslatedSelectOptions,
  useTranslatedField,
  useTranslations,
} from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { DeepPartial } from "../../Common/common";
import { SelectFormFieldOption } from "../../Common/Fields/SelectFormField";

export interface SelectNunavikOrgProps {
  getPrompt: (t: ApplicationFormSpec) => string | undefined;
  getOptions: (t: ApplicationFormSpec) => string[];
  alwaysShowCustom?: boolean;
}

export function SelectOrg({
  getPrompt,
  getOptions,
  alwaysShowCustom,
}: SelectNunavikOrgProps) {
  const { t, iu } = useTranslations("applicant");
  const { t: tCommon, iu: iuCommon } = useTranslations("common");

  const otherLabel = useTranslatedField((t) => t.common.other, " / ");

  const options = useTranslatedSelectOptions(getOptions);

  return (
    <>
      <ConvertToMui getField={getPrompt} />
      <FormSpy<DeepPartial<CFIFormResult>>
        subscription={{ values: true }}
        render={({ values }) => (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={
                alwaysShowCustom || values.applicant?.organization === "Other"
                  ? 6
                  : 12
              }
            >
              <SelectFormField
                name="applicant.organization"
                label={appendWithoutHtml(
                  t,
                  iu,
                  (t) => t.isOrganizationInNunavik.label,
                  " / ",
                )}
                options={[
                  ...options,
                  {
                    value: "Other",
                    text: otherLabel,
                  } satisfies SelectFormFieldOption,
                ]}
                icon={<BusinessIcon />}
              />
            </Grid>
            {(alwaysShowCustom ||
              values.applicant?.organization === "Other") && (
              <Grid item xs={12} md={6}>
                <TextFormField
                  required
                  name="applicant.customOrganization"
                  label={appendWithoutHtml(
                    tCommon,
                    iuCommon,
                    (t) => t.pleaseSpecify,
                    " / ",
                  )}
                />
              </Grid>
            )}
          </Grid>
        )}
      />
    </>
  );
}
