import React from "react";
import { Box, Collapse, Grid } from "@mui/material";
import {
  CheckboxFormField,
  SelectFormField,
  TextFormField,
} from "../../Common/Fields";
import BusinessIcon from "@mui/icons-material/Business";
import {
  useTranslatedField,
  useTranslatedSelectOptions,
} from "../TranslationProvider";
import { useBreakpoints } from "../../Common/useBreakpoints";
import { useFieldValue } from "../../Common/useFieldValue";
import { ApplicationType } from "../Elimination/SelectApplicationType";
import { RelationshipOptions } from "../Applicant/RelationSelector";

export interface ChildrenAddressFieldsProps {
  name: string;
  index: number;
}

export function ChildrenAddressFields({
  name,
  index,
}: ChildrenAddressFieldsProps) {
  const { sm } = useBreakpoints();

  const sameAddressAsApplicantLabel = useTranslatedField(
    (t) => t.children.sameAddressAsApplicant,
    " / ",
  );
  const mailingAddress = useTranslatedField(
    (t) => t.applicant.applicantInfo.mailingAddress,
    " / ",
  );
  const community = useTranslatedField(
    (t) => t.applicant.applicantInfo.community,
    " / ",
  );
  const provinceTerritory = useTranslatedField(
    (t) => t.applicant.applicantInfo.provinceTerritory,
    " / ",
  );
  const postalCode = useTranslatedField(
    (t) => t.applicant.applicantInfo.postalCode,
    " / ",
  );

  const provincesAndTerritories = useTranslatedSelectOptions(
    (t) => t.applicant.applicantInfo.provincesAndTerritories,
  );

  const applicationType = useFieldValue<ApplicationType>("applicationType");
  const relationship = useFieldValue<RelationshipOptions>("relationship");
  const sameAddressAsApplicant = useFieldValue<boolean | undefined>(
    `${name}.sameAddressAsApplicant`,
  );
  const overTenChildren = useFieldValue<"Yes" | "No" | undefined>(
    "overTenChildren",
  );

  const isCloseRelative =
    applicationType === "personal" &&
    (relationship === "parentOrGuardian" ||
      relationship === "fosterParent" ||
      relationship === "self" ||
      relationship === "familyMember");

  return (
    <>
      {isCloseRelative && (
        <Grid item xs={12}>
          <Box pt={1}>
            <CheckboxFormField
              name={`${name}.sameAddressAsApplicant`}
              label={sameAddressAsApplicantLabel}
              showSpace
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Collapse
          in={!isCloseRelative || !sameAddressAsApplicant}
          unmountOnExit
        >
          <Grid container spacing={1}>
            {overTenChildren !== "Yes" && (
              <Grid item xs={12}>
                <TextFormField
                  name={`${name}.mailingAddress`}
                  label={mailingAddress}
                  required
                  icon={<BusinessIcon />}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={overTenChildren === "Yes" ? 6 : 4}>
              <TextFormField
                name={`${name}.community`}
                allowedContent="alpha"
                label={community}
                required
                icon={overTenChildren === "Yes" ? <BusinessIcon /> : undefined}
                showSpace={overTenChildren !== "Yes"}
              />
            </Grid>
            <Grid item xs={12} sm={overTenChildren === "Yes" ? 6 : 4}>
              <SelectFormField
                name={`${name}.provinceTerritory`}
                label={provinceTerritory}
                required
                options={provincesAndTerritories}
                showSpace={!sm}
              />
            </Grid>
            {overTenChildren !== "Yes" && (
              <Grid item xs={12} sm={4}>
                <TextFormField
                  name={`${name}.postalCode`}
                  allowedContent="alphanumeric"
                  maxLength={6}
                  label={postalCode}
                  required
                  showSpace={!sm}
                />
              </Grid>
            )}
          </Grid>
        </Collapse>
      </Grid>
    </>
  );
}
