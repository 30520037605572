import { CFIApplicationKeys } from "../applicationFormFlow";
import { FormNode } from "../../Common/FormFlow";
import { CFIFormResult, PaymentFormResult } from "../cfiFormResult";
import { PaymentTypePrompt } from "./PaymentTypePrompt";
import { PaymentRequestChildren } from "./PaymentRequestChildren";
import { PaymentApplicantInfo } from "./PaymentApplicantInfo";
import { PaymentServices } from "./PaymentServices";
import { RadioFormField, YesNoFormField } from "../../Common/Fields";
import { AddressFields } from "./AddressFields";
import { EmailAndVoidCheque } from "./EmailAndVoidCheque";
import { BankingFields } from "./BankingFields";
import { FileFormField } from "../../Common/Fields/FileFormField";
import React from "react";
import { Stack } from "@mui/material";
import { InvoicesAndOtherDocuments } from "./InvoicesAndOtherDocuments";

export type PaymentKey =
  | "paymentType"
  | "paymentRequestChildren"
  | "paymentApplicant"
  | "paymentServices"
  | "firstTimePaymentRequest"
  | "changedAddress"
  | "addressFields"
  | "preferredPaymentMethod"
  | "changedBankAccount"
  | "emailAndVoidCheque"
  | "bankingInfo"
  | "voidChequeUpload"
  | "invoicesAndOtherDocuments";

export function buildPaymentFlow(): FormNode<
  PaymentFormResult,
  CFIApplicationKeys
>[] {
  return [
    {
      id: "paymentType",
      element: <PaymentTypePrompt />,
      transitions: [
        {
          to: "paymentRequestChildren",
          on: (r) =>
            (r.clientType === "personal" || r.clientType == "group") &&
            r.approvedFileNumber != null,
        },
        {
          to: "paymentApplicant",
          on: (r) => r.clientType != null && r.approvedFileNumber != null,
        },
      ],
    },

    {
      id: "paymentRequestChildren",
      element: <PaymentRequestChildren />,
      transitions: [
        {
          to: "paymentApplicant",
          on: (r) => r.finishedAddingChildren,
        },
      ],
    },

    {
      id: "paymentApplicant",
      element: <PaymentApplicantInfo />,
      transitions: [
        {
          to: "paymentServices",
          on: (r) => {
            console.log(r.applicant);
            return (
              r.applicant?.relationship != null &&
              r.applicant?.firstName != null &&
              r.applicant?.lastName != null &&
              r.applicant?.telephone != null &&
              r.applicant?.email != null &&
              r.applicant?.preferredContact != null
            );
          },
        },
      ],
    },

    {
      id: "paymentServices",
      element: <PaymentServices />,
      transitions: [
        {
          to: "firstTimePaymentRequest",
          on: (r) => r.finishedAddingServices,
        },
      ],
    },

    {
      id: "firstTimePaymentRequest",
      element: (
        <YesNoFormField
          name="firstTimeRequest"
          label={(t) => t.payment.firstTimeRequest}
          showSpace
        />
      ),
      transitions: [
        {
          to: "addressFields",
          on: (r) => r.firstTimeRequest === "Yes",
        },
        {
          to: "changedAddress",
          on: (r) => r.firstTimeRequest === "No",
        },
      ],
    },

    {
      id: "changedAddress",
      element: (
        <YesNoFormField
          name="changedAddress"
          label={(t) => t.payment.changedAddress}
          showSpace
          allowUnsure
        />
      ),
      transitions: [
        {
          to: "addressFields",
          on: (r) => r.changedAddress !== "No" && r.changedAddress != null,
        },
        {
          to: "preferredPaymentMethod",
          on: (r) => r.changedAddress === "No",
        },
      ],
    },

    {
      id: "addressFields",
      element: <AddressFields />,
      transitions: [
        {
          to: "preferredPaymentMethod",
          on: (r) =>
            r.addressInfo?.mailingAddress != null &&
            r.addressInfo?.community != null &&
            r.addressInfo?.postalCode != null &&
            r.addressInfo?.provinceTerritory != null,
        },
      ],
    },

    {
      id: "preferredPaymentMethod",
      element: (
        <RadioFormField
          name="preferredPaymentMethod"
          label={(t) => t.payment.preferredPaymentMethod.prompt}
          options={[
            {
              value: "cheque",
              text: (t) => t.payment.preferredPaymentMethod.cheque,
            },
            {
              value: "directDeposit",
              text: (t) => t.payment.preferredPaymentMethod.directDeposit,
            },
          ]}
          showSpace
        />
      ),
      transitions: [
        {
          to: "emailAndVoidCheque",
          on: (r) =>
            r.firstTimeRequest === "Yes" &&
            r.preferredPaymentMethod === "directDeposit",
        },
        {
          to: "invoicesAndOtherDocuments",
          on: (r) => r.preferredPaymentMethod === "cheque",
        },
        {
          to: "changedBankAccount",
          on: (r) =>
            r.firstTimeRequest === "No" &&
            r.preferredPaymentMethod === "directDeposit",
        },
      ],
    },

    {
      id: "changedBankAccount",
      element: (
        <YesNoFormField
          name="changedBankAccount"
          label={(t) => t.payment.changedBankAccount}
          showSpace
        />
      ),
      transitions: [
        {
          to: "emailAndVoidCheque",
          on: (t) => t.changedBankAccount === "Yes",
        },
        {
          to: "invoicesAndOtherDocuments",
          on: (t) => t.changedBankAccount === "No",
        },
      ],
    },

    {
      id: "emailAndVoidCheque",
      element: <EmailAndVoidCheque />,
      transitions: [
        {
          to: "bankingInfo",
          on: (r) =>
            r.paymentNotificationEmail != null && r.hasVoidCheque == "No",
        },
        {
          to: "voidChequeUpload",
          on: (r) =>
            r.paymentNotificationEmail != null && r.hasVoidCheque == "Yes",
        },
      ],
    },

    {
      id: "bankingInfo",
      element: <BankingFields />,
      transitions: [
        {
          to: "invoicesAndOtherDocuments",
          on: (r) =>
            r.bankingInfo?.bankName != null &&
            r.bankingInfo?.bankNumber != null &&
            r.bankingInfo?.branchNumber != null &&
            r.bankingInfo?.accountNumber != null,
        },
      ],
    },

    {
      id: "voidChequeUpload",
      element: (
        <FileFormField
          name="documents.voidCheque"
          label={(t) => t.payment.voidChequeDocument}
          multiple
        />
      ),
      transitions: [
        {
          to: "invoicesAndOtherDocuments",
          on: (r) => r.documents?.voidCheque != null,
        },
      ],
    },

    {
      id: "invoicesAndOtherDocuments",
      element: <InvoicesAndOtherDocuments />,
      transitions: [
        {
          to: "conclusion",
          on: (r) => r.finishedAddingDocuments,
        },
      ],
    },
  ];
}
