import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { SelectFormField, TextFormField } from "../../Common/Fields";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import BusinessIcon from "@mui/icons-material/Business";
import {
  useTranslatedSelectOptions,
  useTranslations,
} from "../TranslationProvider";
import { ConvertToMui } from "../../Common/ConvertToMUI";

export interface AddressFieldsProps {}

export function AddressFields({}: AddressFieldsProps) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const { t, iu } = useTranslations("applicant");

  const provincesAndTerritories = useTranslatedSelectOptions(
    (t) => t.applicant.applicantInfo.provincesAndTerritories,
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ConvertToMui getField={(t) => t.payment.addressPrompt} />
      </Grid>
      <Grid item xs={12}>
        <TextFormField
          name="addressInfo.mailingAddress"
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.applicantInfo.mailingAddress,
            " / ",
          )}
          required
          icon={<BusinessIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="addressInfo.community"
          allowedContent="alpha"
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.applicantInfo.community,
            " / ",
          )}
          required
          showSpace
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectFormField
          name="addressInfo.provinceTerritory"
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.applicantInfo.provinceTerritory,
            " / ",
          )}
          required
          options={provincesAndTerritories}
          showSpace={!sm}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="addressInfo.postalCode"
          allowedContent="alphanumeric"
          maxLength={6}
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.applicantInfo.postalCode,
            " / ",
          )}
          required
          showSpace={!sm}
        />
      </Grid>
    </Grid>
  );
}
