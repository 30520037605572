import { FieldRenderProps } from "react-final-form";
import React, { useEffect, useState } from "react";
import { Box, TextField, Tooltip } from "@mui/material";

export type ContentType = "alpha" | "numeric" | "alphanumeric";

function getContentTypeValidationError(
  value: string,
  contentType?: ContentType,
) {
  switch (contentType) {
    case "alpha":
      if (!/^[a-zA-ZÀ-ž- ]*$/.test(value)) {
        return "Content must be letters only. No numbers or special characters.";
      }
      break;
    case "numeric":
      if (!/^[0-9.]*$/.test(value)) {
        return "Numeric characters only.";
      }
      break;
    case "alphanumeric":
      if (!/^[0-9a-zA-ZÀ-ž\s,.]*$/.test(value)) {
        return "Alpha-numeric characters only. No special characters";
      }
      break;
  }
}

export interface TextFieldWrapperProps {
  label?: string;
  required?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  placeholder?: string;
  allowedContent?: ContentType;
  maxLength?: number;
}

export interface TextFormFieldWrapperProps
  extends FieldRenderProps<any>,
    TextFieldWrapperProps {}

export function TextFormFieldWrapper({
  input: { value, onChange, ...input },
  meta,
  label,
  required,
  multiline,
  disabled,
  placeholder,
  allowedContent,
  maxLength,
}: TextFormFieldWrapperProps) {
  const [showContentError, setShowContentError] = useState(false);
  const [contentError, setContentError] = useState("");

  useEffect(() => {
    if (showContentError) {
      const timer = setTimeout(() => setShowContentError(false), 4000);

      return () => clearTimeout(timer);
    }
  }, [showContentError]);

  return (
    <Tooltip title={label ?? ""} placement="top" enterDelay={500}>
      <Box>
        <Tooltip
          title={contentError}
          placement="bottom"
          open={showContentError}
          onClose={() => setShowContentError(false)}
        >
          <TextField
            fullWidth
            label={label}
            required={required}
            multiline={multiline}
            disabled={disabled}
            placeholder={placeholder}
            minRows={multiline ? 3 : undefined}
            maxRows={multiline ? 5 : undefined}
            error={meta.touched && meta.error}
            helperText={meta.touched && meta.error}
            value={value}
            onChange={(e) => {
              const error = getContentTypeValidationError(
                e.target.value,
                allowedContent,
              );

              if (error === undefined) {
                onChange(e.target.value);
              } else {
                setContentError(error);
                setShowContentError(true);
              }
            }}
            inputProps={{ ...input, maxLength: maxLength ?? 512 }}
          />
        </Tooltip>
      </Box>
    </Tooltip>
  );
}
