import React from "react";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { IUnderstandCheckbox } from "../../Common/Fields/IUnderstandCheckbox";
import { useTranslatedField } from "../TranslationProvider";
import { useField } from "react-final-form";
import { ApplicationType } from "../Elimination/SelectApplicationType";

export interface ParentalConsentProofProps {}

export function ParentalConsentProof({}: ParentalConsentProofProps) {
  const {
    input: { value: applicationType },
  } = useField<ApplicationType>("applicationType");

  return (
    <>
      <ConvertToMui
        getField={(t) =>
          applicationType === "group"
            ? t.applicant.parentalConsentProof?.group
            : t.applicant.parentalConsentProof?.personal
        }
      />
      <IUnderstandCheckbox name="hasParentalConsentOrAuthority" showSpace />
    </>
  );
}
