import React from "react";
import { Grid } from "@mui/material";
import { SelectFormField, TextFormField } from "../../Common/Fields";
import {
  appendWithoutHtml,
  mergeIntoSelectOptions,
} from "../../Common/appendInuktitut";
import ServiceIcon from "@mui/icons-material/HomeRepairService";
import { ServiceFormSpy } from "./ServiceFormSpy";
import {
  useEnglishTranslations,
  useTranslatedField,
  useTranslations,
} from "../TranslationProvider";
import { GroupedSelectFormFieldOptions } from "../../Common/Fields/SelectFormField";
import { useFieldValue } from "../../Common/useFieldValue";
import { ApplicationType } from "../Elimination/SelectApplicationType";
import { useServiceOptions } from "../ServiceOptionsProvider";

export interface ServiceSelectorProps {
  name: string;
  index: number;
}

export function ServiceSelector({ name, index }: ServiceSelectorProps) {
  const { t, iu } = useTranslations("service");
  const en = useEnglishTranslations();
  const otherLabel = useTranslatedField((t) => t.common.other, " / ");

  const applicationType = useFieldValue<ApplicationType>("applicationType");

  const { categories, isLoading } = useServiceOptions(applicationType);

  return (
    <ServiceFormSpy
      index={index}
      render={(service, state) => (
        <>
          <Grid
            item
            xs={12}
            sm={state.applicationType !== "personal" ? 12 : 6}
            md={
              //Very weird looking, but hey, it's only 2 ternaries instead of 3!
              (state.applicationType !== "personal" ? 12 : 8) /
              (service.service?.startsWith("Other") ? 2 : 1)
            }
          >
            <SelectFormField
              name={`${name}.service`}
              label={appendWithoutHtml(
                t,
                iu,
                (t) => t.serviceLabels.services,
                " / ",
              )}
              options={categories ?? []}
              icon={<ServiceIcon />}
            />
          </Grid>
          {service.service?.startsWith("Other") && (
            <Grid
              item
              xs={12}
              sm={12}
              md={state.applicationType !== "personal" ? 6 : 4}
            >
              <TextFormField
                name={`${name}.customService`}
                label="Custom service"
              />
            </Grid>
          )}
        </>
      )}
    />
  );
}
