import React from "react";
import { Grid } from "@mui/material";
import { SelectFormField, TextFormField } from "../../Common/Fields";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useField } from "react-final-form";
import {
  useTranslatedField,
  useTranslatedSelectOptions,
} from "../TranslationProvider";
import FlagIcon from "@mui/icons-material/Flag";
import ShapesIcon from "@mui/icons-material/Interests";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { useFieldValue } from "../../Common/useFieldValue";
import { ApplicationType } from "../Elimination/SelectApplicationType";

export interface FundingReasonProps {}

export function FundingReason({}: FundingReasonProps) {
  const applicationType = useFieldValue<ApplicationType>("applicationType");

  const fundingReasonPrompt = useTranslatedField(
    (t) => t.service.fundingReason.prompt,
    " / ",
  );
  const fundingReasons = useTranslatedSelectOptions(
    (t) => t.service.fundingReason.options,
  );
  const other = useTranslatedField((t) => t.common.other);
  const pleaseSpecify = useTranslatedField((t) => t.common.pleaseSpecify);

  const requestGoal = useTranslatedField(
    (t) =>
      applicationType === "group"
        ? t.service.groupRequestGoal
        : t.service.projectRequestGoal,
    " / ",
  );
  const requestContext = useTranslatedField(
    (t) =>
      applicationType === "group"
        ? t.service.groupRequestContext
        : t.service.projectRequestContext,
    " / ",
  );

  const requestNeed = useTranslatedField(
    (t) => t.service.groupRequestNeed,
    " / ",
  );

  const {
    input: { value: fundingReason },
  } = useField<string>("fundingReason");

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SelectFormField
          name="fundingReason"
          label={fundingReasonPrompt}
          options={[
            ...fundingReasons,
            {
              value: "Other",
              text: other,
            },
          ]}
          required
          icon={<HelpOutlineIcon />}
        />
      </Grid>
      {fundingReason === "Other" && (
        <Grid item xs={12}>
          <TextFormField
            name="customFundingReason"
            label={pleaseSpecify}
            required
            showSpace
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextFormField
          name="requestGoal"
          label={requestGoal}
          required
          icon={<FlagIcon />}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFormField
          name="requestContext"
          label={requestContext}
          required
          icon={<ShapesIcon />}
        />
      </Grid>
      {applicationType === "group" && (
        <Grid item xs={12}>
          <TextFormField
            name="requestNeed"
            multiline
            label={requestNeed}
            icon={<AnnouncementIcon />}
            required
          />
        </Grid>
      )}
    </Grid>
  );
}
