import React from "react";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslatedField } from "../TranslationProvider";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { FieldArray } from "react-final-form-arrays";
import { PaymentServiceSelector } from "./PaymentServiceSelector";
import { SelectFormField, TextFormField } from "../../Common/Fields";
import MoneyIcon from "@mui/icons-material/AttachMoney";
import { FormSpy } from "react-final-form";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import { useFieldValue } from "../../Common/useFieldValue";
import { PaymentClientType, PaymentFormChild } from "../cfiFormResult";

export interface PaymentServicesProps {}

export function PaymentServices({}: PaymentServicesProps) {
  const costLabel = useTranslatedField((t) => t.service.payment.cost, " / ");
  const add = useTranslatedField((t) => t.service.addService, " / ");
  const next = useTranslatedField((t) => t.common.next, " / ");
  const child = useTranslatedField(
    (t) => t.service.serviceLabels.children,
    " / ",
  );

  const clientType = useFieldValue<PaymentClientType>("clientType");
  const requestChildren = useFieldValue<PaymentFormChild[]>("children");

  return (
    <Stack gap={2}>
      <ConvertToMui getField={(t) => t.payment.services.prompt} />
      <FieldArray
        name="services"
        render={({ fields }) => (
          <Stack gap={2} divider={<Divider />}>
            {fields.map((name, index) => (
              <Stack direction="row" gap={2}>
                <Grid flex={1} container spacing={1}>
                  <PaymentServiceSelector name={name} />
                  {clientType === "personal" && (
                    <Grid item xs={12} sm={6}>
                      <SelectFormField
                        name={`${name}.child`}
                        options={requestChildren.map((c) => ({
                          value: c.id,
                          text: `${c.firstName} ${c.lastName}`,
                        }))}
                        label={child}
                        icon={<PersonIcon />}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={clientType === "personal" ? 6 : 12}>
                    <TextFormField
                      name={`${name}.cost`}
                      label={costLabel}
                      icon={<MoneyIcon />}
                      allowedContent="numeric"
                      required
                    />
                  </Grid>
                </Grid>
                <Button
                  color="error"
                  variant="outlined"
                  disabled={fields.length == 1}
                  onClick={() => fields.remove(index)}
                  sx={{ minWidth: 0 }}
                >
                  <DeleteIcon fontSize="small" />
                </Button>
              </Stack>
            ))}
            <Stack direction="row" gap={2}>
              <Button
                onClick={() => fields.push({})}
                variant="outlined"
                sx={{ flex: 1 }}
              >
                {add}
              </Button>
              <FormSpy
                subscription={{ valid: true }}
                render={({ valid, form: { submit, mutators } }) => (
                  <Button
                    variant="contained"
                    //A sneaky hack: If someone clicks Next with invalid fields, we want to show the validation errors
                    // for those fields. The easiest way to do that is to just submit the form, but we don't want them
                    // to submit the form when the fields are actually valid, so we only submit IF the fields are
                    // invalid. Otherwise, we progress like normal
                    onClick={() =>
                      !valid ? submit() : mutators.finishedAddingServices()
                    }
                  >
                    {next}
                  </Button>
                )}
              />
            </Stack>
          </Stack>
        )}
      />
    </Stack>
  );
}
